import React from 'react'

import {injectShopContainer} from './ShopDataContainer'
import makeCloudinaryUrlForPhoto from './makeCloudinaryUrlForPhoto'


class InfoHeader extends React.Component {
  render() {
    if (!this.props.shop) {return null}
    let {
      logo,
      name, phone,
      address_line_1, address_line_2, city, state, zip
    } = this.props.shop
    return(
      <header className="site-header">
        {/* <h1 className="site-title">{name}</h1> */}
        {/* <h1 className="site-title">
          {logo ? <img
            // style={{}}
             src={makeCloudinaryUrlForPhoto(logo, {width: 400})} alt=""/>:
            <span className="text-logo">{name && name.en}</span>
           }
        </h1> */}

        <div className="header__info">
          {/* <div className="header__contact-info">
            <div className="address">
              {address_line_1}
            </div>
            {city ? <div className="city">{city}, {state} {zip}</div> : null}
            <div className="phone">
              {phone}
            </div>
          </div> */}

          {(name && name.en === 'My Fish Stop') && <ul className="header__links">
            <li><a href="https://res.cloudinary.com/dgps1radv/image/upload/v1511057276/my-fish-stop-catering_ydkkzl.pdf">Catering →</a></li>
            {/* <li><a href="https://www.ubereats.com/en_US/stores/d4b8dcca-8dff-449d-825e-2ad381e51f3a/">Delivery</a></li> */}
          </ul>}
        </div>

      </header>
    )
  }
}

export default injectShopContainer(InfoHeader)
