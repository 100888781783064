
export default function applyDiscount(menu) {
  if (menu.shopId === "urban") {
    return Object.assign({}, menu, {
      subMenus: menu.subMenus.map((subMenu) => {
        if (['wine'].indexOf(subMenu.tag) !== -1) {
          return Object.assign({}, subMenu, {
            // apply discount
            products: subMenu.products.map((product) => {
              return Object.assign({}, product, {
                originalPrice: product.price,
                price: Math.floor(product.price * 0.5 * 100) / 100
              })
            })
          })
        }
        return subMenu
      })
    })
  }
  if (menu.shopId === "frontierwok") {
    return Object.assign({}, menu, {
      subMenus: menu.subMenus.map((subMenu) => {
        if (true) {
          return Object.assign({}, subMenu, {
            // apply discount
            products: subMenu.products.map((product) => {
              return Object.assign({}, product, {
                originalPrice: product.price,
                price: Math.floor(product.price * 0.9 * 100) / 100
              })
            })
          })
        }
        return subMenu
      })
    })
  }


  // if (menu.shopId === "newdeal") {
  //   return Object.assign({}, menu, {
  //     subMenus: menu.subMenus.map((subMenu) => {
  //       if (['burgers'].indexOf(subMenu.tag) !== -1) {
  //         return Object.assign({}, subMenu, {
  //           // apply discount
  //           products: subMenu.products.map((product) => {
  //             return Object.assign({}, product, {
  //               originalPrice: product.price,
  //               price: Math.floor(product.price * 0.5 * 100) / 100
  //             })
  //           })
  //         })
  //       }
  //       return subMenu
  //     })
  //   })
  // }


  return menu

}
