import React from 'react'
import {Link} from 'react-router-dom'
import format from 'date-fns/format'
import {Consumer} from './ShopDataContainer'


const endpoint = "https://us-central1-afa-airtables.cloudfunctions.net/getCalendarForId"
const TEST = "ghoc6a8914tc70e2pqq6vnhi4o@group.calendar.google.com"

class GoogleCalendar extends React.Component {
  static defaultProps = {
    mini: true
  };

  state = {
    icalEvents: null
  };

  componentWillMount() {

  }

  async componentDidMount() {
    let response = await fetch(`${endpoint}?cal=${this.props.calId}`)
    let json = await response.json()
    console.log(json)
    this.setState({icalEvents: json })

  }

  componentWillUnmount() {

  }

  render() {

    console.log('events: ', this.props.googleCalendarId)
    // if (!this.props.googleCalendarId) { return null }
    if (!this.state.icalEvents || this.props.googleCalendarId) {
      return <p style={{
        textAlign: 'center',
        fontWeight: "600"
      }}>"loading recent events..."</p>
    }
    if (!this.state.icalEvents) {return null}
    let {futureEvents=[], metadata={}} = this.state.icalEvents || {}
    let mini = this.props.mini
    let recentEvents = futureEvents.slice(0,4)
    let eventsToUse = futureEvents
    if (mini) {
      eventsToUse = recentEvents
    }
    // let {timeZone} = metadata
    return (
      <section className="events-mini">

        <ul className="event-list">
          {eventsToUse.map((event) => {
            const {location, description="", summary=""} = event
            let start = new Date(event.start)
            let end = new Date(event.end)
            return (
              <li key={event.uid} className="event-item">
                <p className="calendar-date">
                  <span className="day-of-week">{format(start, 'iii')}</span>
                  <span className="month-day">{format(start, 'MMM d')}</span>
                  {/*<span className="day">{format(start, 'DD')}</span>*/}
                </p>
                <div className="time-and-details">
                  <p className="summary">{(summary)}</p>
                  <p className="time">{`${format(start, 'h:mma')} - ${format(end, 'h:mma')}`}</p>
                  {location ? <p className="location">
                    <a target="_blank" href={`https://maps.google.com/?q=${event.location}`}>{`loc: ${location}`}</a>
                  </p> : null}
                  {!mini && <div className="description">
                    {(description)}
                  </div>}
                </div>
              </li>
            )
          })}
        </ul>
        {mini && <p className="more-events">
          {(futureEvents.length > 0) ? <Link to={this.props.fullScheduleLink} className="more-events-link">{`view all ${futureEvents.length} future events`}</Link>:
          "no events planned yet."}
        </p>}
      </section>)
  }
}

const WithId = (props) => (
  <Consumer>
    {({menu}) => {
      console.log('withid: ', menu && menu.googleCalendarId)
      if (!menu || !menu.googleCalendarId) {return null}
      return (<GoogleCalendar calId={props.icalId || menu.googleCalendarId} {...props} />)
    }}
  </Consumer>
)

export default WithId
