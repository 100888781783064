import React from 'react'
// import emoji from '../react-easy-emoji'
// import {CloudinaryContext, Image, Transformation} from 'cloudinary-react'
import cloudinary from 'cloudinary-core'
import PropTypes from 'prop-types'
import CartAddToCartButton from './CartAddToCartSimpleButton'
import MagGlass from './MagGlassIcon'
import {Link} from 'react-router-dom'

import makeCloudinaryUrlForPhoto from './makeCloudinaryUrlForPhoto'

let MAX_ITEMS = 3

class MenuCategoryGridView extends React.Component {
  state = {
    showAll: false
  }
  showRestOfCategory= () => {
    this.setState({ showAll: true })
  }
  render() {

    let className="photos grid"
    let isGallery = false
    let items = this.props.items
    let disableOrder = this.props.disableOrder
    let itemsToDisplay = this.state.showAll ? items : items.slice(0,MAX_ITEMS)
    return (
      <div className="menu-category-grid-view">

        {/* List/Grid of Items */}
        <div className={className}>
          {itemsToDisplay.map(
            i => <MenuItem isGallery={isGallery}
              disableOrder={disableOrder}
              key={i.id} {...i}
              openPhotoSwipeGallery={this.props.openPhotoSwipeGallery}
              width={this.props.width}></MenuItem>)}
        </div>

        {/* SHOW ALL BUTTON  */}
        {(items.length > MAX_ITEMS) && (!this.state.showAll) &&
          <div className="show-more-items">
            <button
              className="button"
              onClick={this.showRestOfCategory}
              >Show {items.length - MAX_ITEMS} other {this.props.categoryName}
          </button>
        </div>
        }

        </div>
    );
  }
}

export default MenuCategoryGridView

class MenuItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let { id, name, photo, width='600', description, mandatory, price, modifiers } = this.props
    name = name && name.en
    description = description && description.en


    if (!photo) {
      return null
    }
    let cloudinaryURL
    if (photo) {
      cloudinaryURL = makeCloudinaryUrlForPhoto(photo, {width})
    }

    return (

        <span className="menu-item"
          style={{width:`${width/2}px`}}
          >


          <div onClick={this.props.openPhotoSwipeGallery.bind(this, id)}>
            <img src={cloudinaryURL}
                width={width}
                alt=""/>
            <MagGlass></MagGlass>
          </div>



          <span className="food-name">{name}</span>
          <span className="price">{price}</span>
          <p className="menu-item__description">{description}</p>



          {!this.props.disableOrder && <CartAddToCartButton product={this.props} />}
          {/* {!this.props.disableOrder && <Link to={this.props.id}></Link>} */}

        </span>

    );
  }
}
MenuItem.contextTypes = {
  addToCart: PropTypes.func
}
