export function validateCustomerPicksForProduct(product) {
  // const {customerPicks={}, modgroups=[]} = product
  var customerPicks = product.customerPicks || {};
  var modgroups = product.modgroups || [];
  var resultsMap = {};
  var resultsArray = [];
  modgroups.forEach(function (_ref) {
    var modgroupId = _ref.id,
        required = _ref.required,
        min = _ref.min,
        max = _ref.max,
        modifiers = _ref.modifiers;

    if (!modifiers || modifiers.length === 0) {
      return;
    } // need to contain modifiers


    if (required) {
      var pickedModifier = modifiers.find(function (_ref2) {
        var modifierId = _ref2.id;
        return customerPicks[modifierId];
      });

      if (!pickedModifier) {
        // customer did not make a requried selection
        var errorObj = {
          modgroupId: modgroupId,
          message: 'This selection is required'
        };
        resultsMap[modgroupId] = errorObj;
        resultsArray.push(errorObj);
      }
    }
  });
  return {
    resultsMap: resultsMap,
    resultsArray: resultsArray,
    validated: resultsArray.length === 0 // check if

  };
}