import React, { Component, PureComponent } from 'react';
import {NavLink, Link, Redirect} from 'react-router-dom'
import Catering from './Catering'
import Rotisserie from './Rotisserie'
// import Menu from './Menu'
import Gallery from './Gallery'
import './index.scss'
import PoweredBy from './PoweredBy'
export const customOrderAppPaths = ["/menu", "/order"]

export const pages = [
  {path: "/order", label: "Order"},
  {path: "/menu", label: "Menu" },
  {path: "/chicken", label: "Rotisserie", component: Rotisserie},
  // {path: "/gallery.html", label: "Gallery", component: Gallery},
  // {path: "/contact.html", label: "Contact", component: Contact},
]

export class SiteNav extends PureComponent {
  render() {

    return (
    <nav className="pages-nav">
      <ul>
        {pages.map(({path, label, external}) => (
          <li>
            {external ?
              <a className="page-link" href={path}>{label}</a> :
              <NavLink className="page-link" exact to={path}>{label}</NavLink>}
          </li>
        ))}
      </ul>
    </nav>
  )}
}



export class Header extends PureComponent {
  render() {
    return (
      <div className="landing-page-wrapper">
        <div className="hero-banner">
          <div className="text">
            <p>French <br/>Vietnamese <br/>Fusion</p>
            <p>
              <Link to="/order" className="order-online-button">Order Pickup / Delivery</Link>
            </p>
          </div>
        </div>
        <div className="landing-page">
          <div className="text">
            <h2>Best Rotisserie Chicken in the World</h2>
            <p>
              Our famous roast chicken have been past down since 1983 in France for 3 generations.
              We brought it here to Burbank, California.
            </p>
            <p><Link className="order-online-button" to="/chicken">Taste The Chicken</Link></p>
            {/* <p className="attribution">
              <span>— Bill Addison,</span>
              <img
                className="la-times-logo"
                style={{height: "25px"}}
                src="https://afag.imgix.net/hayats-kitchen/la-times-logo.png?h=80"
                alt="— Los Angeles Times"/>
            </p> */}
            {/* <p>Straight from Hong Kong. Best in LA.</p> */}
            {/* <p>Vist Us -></p> */}
            {/* <p>Fine Lebanese Food.</p> */}
            {/* <Link to="/order" className="order-online-button">Order Online</Link> */}
          </div>


        </div>
        <div className="location-info">
          <h2>Hours</h2>
          <p>Sunday to Thursday: 11am to 9pm</p>
          <p>Friday Saturday: 11am to 10pm</p>
          <h2>Address</h2>
          <p>124 N San Fernando Blvd</p>
          <p>Burbank, CA 91502</p>
          <h2>Phone</h2>
          <a href="tel:+18182389988">(818) 238-9988</a>
        </div>
      </div>
    );
  }
}


export class OrderingHeader extends PureComponent {
  render() {
    return (
      <div className="ordering-header">
        {/* <PoweredBy></PoweredBy> */}
      </div>
    );
  }
}

export function subMenuFilter (subMenu) {
  const tag = subMenu.tag
  if (!tag) {return true}

  const now = new Date()
  const day = now.getDay() // Sunday - Saturday : 0 - 6
  const hours = now.getHours() //0 to 23
  const minutes = now.getMinutes() // 0 to 59
  const isWeekday = [1,2,3,4,5].indexOf(day) !== -1
  const isLunch = (
    (hours >= 11) && (minutes >= 30) && (hours < 15)
  ) || (
    (hours >= 12) && (hours < 15)
  )
  const showLunchMenu = isWeekday && isLunch

  const isLunchSubmenu = tag.indexOf('lunch') !== -1
  const isDinnerSubmenu = tag.indexOf('dinner') !== -1

  if (isLunchSubmenu && showLunchMenu) { return true }
  if (isDinnerSubmenu && !showLunchMenu) { return true }

  return false
}
