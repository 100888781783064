import React from 'react'
import PlacesAutocomplete from './Autocomplete'
import getDirections from './getDirections'
import {get, round} from 'lodash'
import {geocodeByPlaceId} from './helpers'
import { Subscribe } from 'unstated';
import CartContainer from '../react/CartContainer'
import {Link} from 'react-router-dom'
import {injectShopContainer} from '../react/ShopDataContainer'
import calcDelivery from '../sharedUtils/calcDelivery'
import PickReadyTime from '../Checkout/PickReadyTime'
import logEvent from '../utils/logEvent'
import {searchOptions, fetchPredictionsDebounceMS} from './constants'
// LOCAL
import {geocodeAddressComponentsToNinja} from 'getAddressComponents'


const MINUTES_TO_DISPATCH = 45
const MS_TO_DISPATCH = MINUTES_TO_DISPATCH * 60 * 1000
// const ORIGIN = "3122 W Burbank Blvd, Burbank, CA 91505"
// const ORIGIN_COORDINATES = { lat: 34.172386, lng: -118.456837 }

class DeliveryAddress extends React.Component {
  state = {
    ready: false,
    address: this.props.currentAddress || '',
    distance: null,
    distanceHistoricAverage: null,
    durationInTraffic: null,
    deliveryFeeInCents: null,
    isDeliverable: null,
    deliveryErrorMessage: null
  }
  componentDidMount() {
    if (!window.google) {
      window['initDirections'] = this.init;
    } else {
      this.init();
    }
  }
  init = () => {
    console.log('calling init() on Delivery Address')
    if (!window.google) {
      throw new Error(
        'Delivery Address: Google Maps JavaScript API library must be loaded. See: https://github.com/kenny-hibino/react-places-autocomplete#load-google-library'
      );
    }

    if (!window.google.maps.places) {
      throw new Error(
        'Delivery Address: Google Maps Places library must be loaded. Please add `libraries=places` to the src URL. See: https://github.com/kenny-hibino/react-places-autocomplete#load-google-library'
      );
    }

    // SAVED ADDRESS prefill:
    if (this.state.address) {
      this.getDirectionsInfo(this.state.address)
    }

    this.setState(state => {
      if (state.ready) {
        return null;
      } else {
        return { ready: true };
      }
    });
  };
  handleChange = (address, placeId) => {
    this.setState({ address })
  }

  getAddressComponentsFromRouteResult = async (result) => {
    try {
      const placeId = get(result, 'geocoded_waypoints[1].place_id', null)
      if (!placeId) { throw new Error ('no place id found in route result') }
      const geocodeResult = await geocodeByPlaceId(placeId)
      const components = geocodeAddressComponentsToNinja(geocodeResult[0].address_components)
      console.log('found address components:', components)
      this.props.setDeliveryInfo({ addressComponents: components })
      // const addressString = components.addressString
      // this.setState({ address: addressString }) don't set in case the compoentns are the restaurant address
      // this.props.setDeliveryInfo({ deliveryAddress: addressString, addressComponents: components })
    } catch (e) {
      // console.log(`conversion of saved address to component failed`)
      // console.log(e)
      window.Sentry.captureMessage(`conversion of saved address to component failed`)
      window.Sentry.captureException(e)
    }
  }

  getDirectionsInfo = (address) => {
    const settings = this.props.settings || {}
    const {coordinates, deliveryAddress} = settings
    const directionsService = new window.google.maps.DirectionsService()
    directionsService.route({
      origin: deliveryAddress,
      destination: address,
      travelMode: "DRIVING",
      drivingOptions: {
        departureTime: new Date(Date.now() + MS_TO_DISPATCH), //seconds since epoch
      }


    }, (result, status) => {
      if (status == 'OK') {
        console.log(result)
        // FOR old pre-saved addresses without address components,
        // get the components here, but if failed, let user proceed.
        const streetAdd = this.props.addressComponents && this.props.addressComponents.DestinationAddress1
        const streetAddIsInUserFacingString = address && (address.indexOf(streetAdd) !== -1)
        console.log('add component and string matches: ', streetAddIsInUserFacingString)
        if (!this.props.addressComponents || !streetAddIsInUserFacingString) {
          console.log('generating components...')
          try {
            this.getAddressComponentsFromRouteResult(result)
          } catch (e) { window.Sentry.captureException(e) }
        }
        //
        const distance = round(get(result, "routes[0].legs[0].distance.value") * 0.00062, 1)
        const durationHistoricAverage = Math.round(get(result, "routes[0].legs[0].duration.value") / 60)
        const durationInTraffic = Math.round(get(result, "routes[0].legs[0].duration_in_traffic.value") / 60)
        // console.log(distance)
        const {totalPrice: subtotal } = this.props.getSubtotals()
        const {delivery={}} = settings
        const {deliveryFeeInCents,
          isDeliverable} = calcDelivery({
            deliverySettings: delivery,
            miles: distance,
            minutes: durationInTraffic,
            subtotal: subtotal
          })
        this.props.setDeliveryInfo({
          distanceInMiles: distance, deliveryFeeInCents, isDeliverable})
        this.setState({deliveryAddress: address, distance, durationHistoricAverage, durationInTraffic, deliveryFeeInCents, isDeliverable,deliveryErrorMessage: null})
        try {
          logEvent(`selectAddress`, `${distance}mile - $${deliveryFeeInCents} - ${isDeliverable} - ${address}`)
        } catch (e) {console.log(e)}

      }
    })
  }

  handleSelect = async (deliveryAddress, placeId) => {
    try {

      const geocodeResult = await geocodeByPlaceId(placeId)
      const components = geocodeAddressComponentsToNinja(geocodeResult[0].address_components)
      const addressString = components.addressString
      this.setState({ address: addressString })
      this.props.setDeliveryInfo({ deliveryAddress: addressString, addressComponents: components })
      // this.props.setDeliveryInfo({ deliveryAddress }) //old
      this.getDirectionsInfo(deliveryAddress)

    } catch (e) {
      this.props.setDeliveryInfo({ isDeliverable: false })
      this.setState(
        {
          isDeliverable: false,
          deliveryErrorMessage: e.message
        })
      window.Sentry.captureMessage(`${deliveryAddress} is rejected due to postal address error`)
      window.Sentry.captureException(e)
    }
    // geocodeByAddress(address)
    //   .then(results => getLatLng(results[0]))
    //   .then(latLng => console.log('Success', latLng))
    //   .catch(error => console.error('Error', error))
  }

  render() {
    const {address, distance, durationHistoricAverage, durationInTraffic, deliveryFeeInCents, isDeliverable,deliveryErrorMessage} = this.state
    const settings = this.props.settings || {}

    const {
      coordinates={},
      deliveryAddress,
      delivery={},
      leadTime={},
    } = settings
    const {
      maxDistanceInMiles,
      defaultOrderStartsDropoffMinutes,
      leaveAtDoorPostmates,
      leaveAtDoorPostmatesNote,
    } = delivery

    const replacementPostmatesLeaveAtDoorNote = leaveAtDoorPostmatesNote && leaveAtDoorPostmatesNote.length > 5 && leaveAtDoorPostmatesNote

    // console.log('BUGH', leadTime.defaultDeliveryEstimate)
    const minutesToDispatch =
      Number(defaultOrderStartsDropoffMinutes) ||
      Number(leadTime.defaultDeliveryEstimate) ||
      MINUTES_TO_DISPATCH
    const lat = Number(coordinates.lat)
    const lng = Number(coordinates.lng)

    // const {deliveryFeeInCents, isDeliverable} = calcDelivery({deliverySettings: delivery, miles: distance, minutes: durationInTraffic})

        return(
          <div className="delivery-address-finder">

            <PlacesAutocomplete
              value={this.state.address}
              debounce={fetchPredictionsDebounceMS}
              onChange={this.handleChange}
              onSelect={this.handleSelect}
              optionsLocationLiteral={{lat, lng}} //43.055459, -87.905264
              searchOptions={searchOptions}
              googleCallbackName="initPlacesAutcomplete">
              {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                <div className="autocomplete__wrapper">
                  {/* <p>From: Restaurant</p> */}

                  <label className="deliver-address-finder__label" htmlFor="location-search-input">Address: </label>
                  <input
                    {...getInputProps({
                      placeholder: 'enter address...',
                      className: 'location-search-input',
                      id: "location-search-input"
                    })}
                  />
                  <div className="autocomplete-dropdown-container">
                    {suggestions.map(suggestion => {
                      const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
                      // inline style for demonstration purpose
                      const style = suggestion.active
                      ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                      : { backgroundColor: '#ffffff', cursor: 'pointer' };
                      return (
                        <div {...getSuggestionItemProps(suggestion, { className, style })}>
                          <span>{suggestion.description}</span>
                          {(process.env.NODE_ENV === 'development') &&
                          <div style={{color: "purple"}}>{suggestion.prediction.types.join(',')}</div>
                          }
                        </div>
                      )
                    })}
                    {(suggestions && (suggestions.length > 0)) && <div className="attribution">
                      <img src="/powered_by_google_on_white.png" alt=""/>
                    </div>}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>

            {(isDeliverable === false) && <div>
              {deliveryErrorMessage ||
                `This address is beyond our ${maxDistanceInMiles} mile max delivery distance.`
              }

            </div>}

            {isDeliverable && <div
              className="delivery-fee-details"
              >
              Fee: <span className="number">${deliveryFeeInCents / 100}</span> |
              Miles: <span className="number">{distance}</span> |
              ETA: <span className="number">{minutesToDispatch + durationInTraffic}min</span>
            </div>}

            <div className="delivery-address-finder__additional-info">
              <div className="input-group">
                <label htmlFor="apt-number" className="deliver-address-finder__label">Apt/Unit Number:</label>
                <input
                  onChange={(e) => this.props.setDeliveryInfo({ unitNumber: e.target.value || '' })}
                  value={this.props.unitNumber}
                  id="apt-number" type="text"  />
              </div>
              <div className="input-group delivery-instructions">
                <label htmlFor="delivery-instructions" className="deliver-address-finder__label">Delivery Instructions:</label>
                {!leaveAtDoorPostmates ?
                <textarea
                  onChange={(e) => this.props.setDeliveryInfo({ deliveryInstructions: e.target.value || '' })}
                  value={this.props.deliveryInstructions}
                  name="" id="delivery-instructions" rows="1"></textarea>
                  :
                <div className="leave-at-door-postmates">
                  <div className="contact-less">contactless-only:</div>
                  <p className="textarea-forced">
                    {replacementPostmatesLeaveAtDoorNote ||
                      <span>Our delivery partner Postmates can only drop off order at the door.


                      </span>
                    }
                  </p>

                  <label
                    htmlFor="customer-phone-double-check">Receive text alerts:</label>
                  <input
                    name="phone"
                    value={this.props.cart.state.phone}
                    onChange={(e) => {
                      this.props.cart.setStateAndSave({phone: e.target.value})
                    }}
                    required
                    type="text" id="customer-phone-double-check" />
                </div>
                }
              </div>
            </div>


            <PickReadyTime type="delivery"></PickReadyTime>
          </div>
        )
  }
}

const SubscribedDeliveryAddress = ({settings}) => {
  if (!settings) {return null}
  return(<Subscribe to={[CartContainer]}>{(cart) => {
    return(
      <DeliveryAddress
        cart={cart}
        getSubtotals={cart.getSubtotals}
        settings={settings}
        currentAddress={cart.state.deliveryAddress}
        addressComponents={cart.state.addressComponents}
        unitNumber={cart.state.unitNumber}
        deliveryInstructions={cart.state.deliveryInstructions}
        setDeliveryInfo={cart.setDeliveryInfo}
      />
    )
  }}</Subscribe>)
}

export default injectShopContainer(SubscribedDeliveryAddress)
