// turn google placeId returned "addressComponents" into postal address components
export function geocodeAddressComponentsToNinja(addressComponents) {
  var requriedTypes = ["street_number", "route", "locality", "administrative_area_level_1", "postal_code"];
  var components = {};
  requriedTypes.forEach(function (type) {
    var component = addressComponents.find(function (comp) {
      if (comp.types.includes(type)) {
        return true;
      }
    });

    if (!component && ["street_number", "locality"].includes(type)) {
      throw new Error("Cannot find postal address for location. (no ".concat(type, " info)"));
    }

    components[type] = component || null;
  });
  var street_number = components.street_number,
      route = components.route,
      locality = components.locality,
      administrative_area_level_1 = components.administrative_area_level_1,
      postal_code = components.postal_code;
  var DestinationAddress1 = "".concat(street_number.long_name, " ").concat(route.long_name);
  var DestinationCity = locality.long_name;
  var DestinationStateId = administrative_area_level_1.short_name;
  var DestinationZipcode = postal_code.long_name;
  var addressString = "".concat(DestinationAddress1, ", ").concat(DestinationCity, ", ").concat(DestinationZipcode);
  return {
    DestinationAddress1: DestinationAddress1,
    DestinationCity: DestinationCity,
    DestinationStateId: DestinationStateId,
    DestinationZipcode: DestinationZipcode,
    addressString: addressString
  };
}