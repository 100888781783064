import React, { Component } from 'react';
import {injectCartContainer} from '../react/CartContainer'
import {injectShopContainer} from '../react/ShopDataContainer'
import {format} from 'date-fns/esm'
import ErrorBoundary from '../utils/ErrorBoundary'
import logEvent from '../utils/logEvent'


class PickReadyTime extends Component {
  state = {
    showTimeEditor: true,
    timeSlots: []
  }
  showTimeEditor = () => {
    this.setState({showTimeEditor: true})
  }

  componentDidMount = () => {
    this.timer = setInterval(() => {
      this.generateTimeList()
    }, 1 * 60 * 1000)
    this.generateTimeList()
  }
  componentWillUnmount = () => {
    clearInterval(this.timer)
  }
  updateCurrentTime = ({timeSlots, asapAvailable}) => {
    const {pickupTime, deliveryTime} = this.props.cart.state
    const arrayOfTime = timeSlots.map(({ts}) => ts)
    // set time to default if it isn't valid anymore
    // default: if current time is avaialbe, set to ASAP,
    // if current time is not, set to 'non' - not selected and disable order placing.
    let isSlotAvailable = false
    if (this.props.cart.state.cartType === 'DELIVERY') {
      isSlotAvailable = (arrayOfTime.indexOf(Number(deliveryTime)) !== -1)
      if (!isSlotAvailable) {
        let newTS =  asapAvailable ? 'ASAP' : 'non'
        this.props.cart.setState({deliveryTime: newTS})
        // console.log('updated time to:', newTS)
      }
    } else {
      isSlotAvailable = (arrayOfTime.indexOf(Number(pickupTime)) !== -1)
      if (!isSlotAvailable) {
        let newTS =  asapAvailable ? 'ASAP' : 'non'
        this.props.cart.setState({pickupTime: newTS})
        // console.log('updated time to:', newTS)

      }
    }
    if (isSlotAvailable) {
      // console.log('PickReadyTime: selected time still valid');
      return
    }

  }
  selectTime = (e) => {
    const selectedTS = (e.target.value)
    if (!selectedTS) { return }
    try {logEvent('select time', `selected ${selectedTS}`)} catch (e) { console.log(e)}
    if (this.props.cart.state.cartType === 'DELIVERY') {
      this.props.cart.setState({deliveryTime: selectedTS})
    } else {
      this.props.cart.setState({pickupTime: selectedTS})
    }
  }
  generateTimeList = () => {
    const settings = this.props.settings || {}
    // console.log('gen list:', settings)
    const {hours} = settings
    if (!hours) { return }
    let sheduleType = (this.props.cart.state.cartType === 'DELIVERY') ? 'delivery' : 'pickUp'
    let days = hours[sheduleType] // 'delivery', pickUp or dineIn

    // is ASAP available
    let asapAvailable = false
    let currentTime = Date.now()
    days.forEach(({timeRanges}) => {
      timeRanges.forEach(({from, to}) => {
        if ((from) < currentTime && currentTime < (to)) {
          asapAvailable = true
          console.log('PickReadyTime: ASAP available')
        }
      })
    })

    // make list of options for user
    let list = []
    days.forEach(({timeRanges}) => {
      timeRanges.forEach(({from, to}) => {
        // let estimatedReadyTime = ((scheduleType === 'delivery') ? 45 * 60 * 1000 : 15 * 60 * 1000)
        let estimatedDeliveryTime = 0
        let estimatedFoodPrepTime = 30 * 60 * 1000
        let i
        for (i = from; i < to; i = i + 30 * 60 * 1000) {
          if (i > currentTime) {
            let estimatedReadyTime = i + estimatedFoodPrepTime + estimatedDeliveryTime
            list.push({
              ts: estimatedReadyTime,
              userDisplay: `${format(new Date(estimatedReadyTime), 'eeee h:mma')}`
            })
          }
        }
      })
    })



    this.updateCurrentTime({timeSlots: list, asapAvailable})
    this.setState({timeSlots: list, asapAvailable})
  }
  render() {

    const cart = this.props.cart
    const cartType = cart.state.cartType
    const settings = this.props.settings || {}
    const {hours} = settings
    const isDelivery = (cartType === 'DELIVERY' )
    const {timeSlots} = this.state
    // console.log('ready time!', hours, cart, timeSlots)
    if (!hours || !cart || !timeSlots[0]) {return null}

    const selectedTS = ((isDelivery) ? cart.state.deliveryTime : cart.state.pickupTime) || "non";

    return (
      <div className="pick-ready-time">
        {/* Pick Up: Immediately<button
          onClick={this.showTimeEditor}
          className="link-button">change</button> */}

          <label
            htmlFor="date-time-picker"
            role="text"
            className="label">Choose {isDelivery ? "Delivery Time: " : "Pick Up Time: "}</label>

          <select
            id="date-time-picker"
            value={selectedTS}
            onChange={this.selectTime}>
              {this.state.asapAvailable && <option value="ASAP">ASAP</option>}
            <option value="non">Choose A Time</option>
            {timeSlots.map(({ts, userDisplay}) => {return(
              <option
                key={ts}
                value={ts}>{userDisplay}</option>
            )})}
          </select>
        {this.state.showTimeEditor &&
          <div></div>
        }
      </div>
    );
  }
}

class PickReadyTimeWrapper extends Component {
  render() {
    return (
      <ErrorBoundary name="pick-ready-time">
        <PickReadyTime {...this.props}></PickReadyTime>
      </ErrorBoundary>
    );
  }
}



export default injectShopContainer(injectCartContainer(PickReadyTimeWrapper))
