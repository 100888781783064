import React from 'react'
import {Link, withRouter} from 'react-router-dom'
import { Subscribe } from 'unstated';
import {round} from 'lodash'
import {injectStripe} from 'react-stripe-elements';
import {injectShopContainer} from './ShopDataContainer'

import calcItemPrice from './calcItemPrice'
import calcItemPriceArray from './calcItemPriceArray'
import CartContainer, {injectCartContainer} from './CartContainer'
import makeCloudinaryUrlForPhoto from './makeCloudinaryUrlForPhoto'
import isOrderingOnline from './isOrderingOnline'
import ModgroupDisplay from './CartCheckoutModgroupDisplay'

import DeliveryAddress from '../DeliveryAddress'
import StripePayment from '../StripePayment'
import PaymentRequestButton from '../StripePayment/PaymentRequestButton'
import CashPayment from '../Checkout/CashPayment'
import Cashback from '../Checkout/Cashback'
import PickReadyTime from '../Checkout/PickReadyTime'

import CheckoutTips from '../Checkout/CheckoutTips'
import PromoCode from '../Checkout/PromoCode'

class CartCheckout extends React.Component {
  // constructor(props) {
  //   super(props)
  //   this.focusRef = React.createRef()
  // }

  state = {
    orderButtonPressed: false,
    orderSubmitErrorText: null,
    isOrderingOnline: true,
    nameOnCard: '',
    preTipPrimaryCents: null,
  };

  focusRef = React.createRef()


  goBack = (e) => {
    e.preventDefault()
    let isFromApp = this.props.location.state && this.props.location.state.fromMini
    if (isFromApp) {

      this.props.history.goBack()
    } else {
      this.props.history.push({pathname: '/order'})
    }
  };

  componentDidMount () {
    // console.log('componentDidMount')
    isOrderingOnline((isOrderingOnline) => this.setState({isOrderingOnline}))
    // always scroll to top for this page
    try {window.scrollTo(0,0)} catch (e) {}

    if(this.focusRef.current) {
      try {
        this.focusRef.current.focus()
      } catch(e) {
      }
    }
  }

  componentDidUpdate () {
    const paymentType = this.props.cart && this.props.cart.state.paymentType
    const disableCash = this.props.settings && this.props.settings.disableCash
    const disableCredit = this.props.settings && this.props.settings.disableCredit
    if ((paymentType !== 'CARD') && disableCash && !window['ranUnsafeFunction' + this.props.shopId]) {
      console.log('aleppo special 😆')
      window['ranUnsafeFunction' + this.props.shopId] = true
      this.props.cart.setStateAndSave({paymentType: "CARD"})
    }
    if ((paymentType !== 'CASH') && disableCredit && !window['ranUnsafeFunction' + this.props.shopId]) {
      console.log('aleppo special 😆')
      window['ranUnsafeFunction' + this.props.shopId] = true
      this.props.cart.setStateAndSave({paymentType: "CASH"})
    }

  }

  handleCreateOrder = () => {
    this.setState({ orderButtonPressed: true }, async () => {
      let token
      try {
        const result = await this.props.stripe.createToken({name: 'Test Person'})
        token = result.token
        // console.log('TOKEN:', token)
      } catch (e) {
        console.log(e)
      }

      this.props.cart.createOrder({stripeToken: token}, ({success, text, data}) => {
        if (success) {
          console.log(data)
          if (data.shopId === 'test') {
            this.props.history.push(`/order-status/${data.orderId}/test`)
          } else {
            this.props.history.push(`/order-status/${data.orderId}`)
          }
        } else {
          this.setState({
            orderButtonPressed: false,
            orderSubmitErrorText: text,
          })
        }
      })
    })
  }

  handleTokenEvent = ({complete, token, ...data}) => {

  }

  render() {
    let shopId
    try {
      shopId = this.props.shop.shopId
    } catch(e) {

    }
    console.log('checkout page:', shopId)


    const cart = this.props.cart
    let {totalPrice, numberOfMarketPriceItems=0, discount=0, invoice, subtotal, tax, allServiceFee, deliveryFeeInCents, preTipPrimaryCents, preTipTotalWithTax} = cart.getSubtotals()

    const cartHasNoItems = !(cart.state.products.length > 0)
    let paymentType = cart.state.paymentType
    let cartType = cart.state.cartType
    let settings = this.props.settings
    if (!settings) { return null }

    let {showDelivery, showPickup, enableCashback, cashbackPercentageAsInteger, delivery={}, disableCash, disableCredit, pretip} = settings
    let {maxCashOrderTotalInCents, contactFreeOptions} = settings
    let {minimumDeliverySubtotalInCents=0, disableDelivery, deliveryCreditOnly} = delivery
    disableDelivery = (disableDelivery === "true") || (disableDelivery === true)

    let outstandingOrderConditions = []
    let disableOrdering = (this.state.orderButtonPressed || cartHasNoItems || !this.state.isOrderingOnline)
    let didNotReachMinimumDeliverySubtotal = (totalPrice * 100 < minimumDeliverySubtotalInCents)
    // console.log('debug:', didNotReachMinimumDeliverySubtotal, totalPrice, minimumDeliverySubtotalInCents)
    if (cart.state.cartType === 'DELIVERY') {
      disableOrdering = disableOrdering || !cart.state.isDeliverable || didNotReachMinimumDeliverySubtotal
      if (didNotReachMinimumDeliverySubtotal) {
        outstandingOrderConditions.push(`add items to reach delivery minimum of $${minimumDeliverySubtotalInCents/100}.`)
      }
    }
    if (!isOrderingOnline) {
      disableOrdering = true
    }

    const showPreTip = pretip || (process.env.NODE_ENV === 'development')

    // console.log(outstandingOrderConditions)

    let cashbackPercentage = Number(cashbackPercentageAsInteger)
    enableCashback=true
    showPickup=true
    showDelivery=true

    maxCashOrderTotalInCents = Number(maxCashOrderTotalInCents) || 0
    const creditOnlyExceedMaxInvoice =
      (maxCashOrderTotalInCents > 1000) &&
      (invoice * 100 > maxCashOrderTotalInCents) &&
      (paymentType === "CASH")
    // console.log({invoice, maxCashOrderTotalInCents, creditOnlyExceedMaxInvoice})
    if (cart.state.paymentType === 'CASH') {
      disableOrdering = disableOrdering || creditOnlyExceedMaxInvoice
    }

    return (
      <div className="checkout-container">


        <div className="back">
          <Link to="/order">← Back to Menu</Link>
        </div>
        <h2 className="cart-title"
          ref={this.focusRef}
          tabIndex={-1}>Shopping Bag</h2>

        {cartHasNoItems &&
          <p>Empty Bag, Add items from <Link to="/" onClick={this.goBack}>our menu.</Link></p>
        }

        <ul className="cart-items-list-checkout">
          {cart.state.products.map(item => <CartItem {...item}
            key={item.id}
            showItemCardForId={cart.showItemCardForId}
            removeFromCart={cart.removeFromCart}
           />)}
        </ul>

        <hr aria-hidden className="checkout__section-divider"/>

        {this.state.isOrderingOnline ? null : <section className="offline-messege">online ordering is currently offline, you may still add items to order at a later time.</section>}

        <CashPayment shopId={this.props.shopId}></CashPayment>

        <div className="checkout__order-type-switch-container switch-button-group">
          <h2
            aria-label="section 2: order type"
            className="step-number">2</h2>
          {showPickup && <label htmlFor="pickup" className={(cartType === 'PICKUP') ? 'selected': 'not-selected'}>
            <input type="radio" name="orderType" id="pickup"
              checked={cart.state.cartType === 'PICKUP'}
              onClick={cart.setCartType.bind(this, 'PICKUP')}/>To-Go</label>}
          {!disableDelivery && <label htmlFor="delivery" className={(cartType === 'DELIVERY') ? 'selected': 'not-selected'}>
            <input type="radio" name="orderType" id="delivery"
              checked={cart.state.cartType === 'DELIVERY'}
              onClick={cart.setCartType.bind(this, 'DELIVERY')}/>Delivery</label>}
        </div>

        <div className="cart-type-container">
          {(cart.state.cartType === "DELIVERY" && !disableDelivery) && <div className="checkout__delivery-widget">
            <DeliveryAddress></DeliveryAddress>
          </div>}

          {(cart.state.cartType === "PICKUP") && <div className="checkout__pickup-widget">
            <div>
              <p>Order ready in 25-30min.</p>
              {/* {contactFreeOptions &&
                <div className="contact-free-options">
                  <div className="option">
                    <label htmlFor=""></label>
                    <input
                      checked={cart.state.cartType === 'DELIVERY'}
                      onClick={cart.setContactFreePickup.bind(this, 'CARHOP')}
                      type="checkbox"/>
                  </div>
                  <div className="option">
                    <label htmlFor="">Curbside Pickup</label>
                    <input
                      checked={cart.state.contactFree === 'DELIVERY'}
                      onClick={cart.setContactFreePickup.bind(this, 'CURBSIDE')}
                      type="checkbox"/>
                  </div>
                </div>
              } */}
              <PickReadyTime></PickReadyTime>
            </div>
          </div>}
        </div>



        <hr aria-hidden className="checkout__section-divider"/>

        <div className="checkout__payment-type-switch-container switch-button-group">
          <h2
            aria-label="section 3: payment method"
            className="step-number">3</h2>
            {!disableCash && !(deliveryCreditOnly && cart.state.cartType === 'DELIVERY') && <label htmlFor="cash" className={(paymentType === 'CASH') ? 'selected': 'not-selected'}>
            <input type="radio" name="paymentType" id="cash"
              checked={paymentType === 'CASH'}
              onClick={cart.setStateAndSave.bind(this, {paymentType: 'CASH'})}/>
              <span className="label-text">{(cart.state.cartType === "DELIVERY") ? `Cash` : `Pay In Person`}</span>
            </label>}
            {!disableCredit && <label htmlFor="card" className={(paymentType === 'CARD') ? 'selected': 'not-selected'}>
              <input type="radio" name="paymentType" id="card"
                checked={paymentType === 'CARD'}
                onClick={cart.setStateAndSave.bind(this, {paymentType: 'CARD'})}/>
                <span className="label-text">Credit Card</span>
            </label>}

        </div>

        <div className="place-order-section">

          {/* <h3 className="checkout__section-title">Payment</h3> */}

          <PromoCode></PromoCode>


          {(paymentType === "CASH") && <div
            style={{
              lineHeight: "1.5em",
              padding: "0.5em 0"
            }}
            >
            {creditOnlyExceedMaxInvoice &&
              <div>Orders above ${maxCashOrderTotalInCents / 100} need to be paid with credit card.
              <br/>
              <a href="#" onClick={cart.setStateAndSave.bind(this, {paymentType: 'CARD'})}>switch to credit card</a>
            </div>

            }
          </div>}
          {(paymentType === "CARD") &&
          <div className="card-section">
            {showPreTip && <CheckoutTips
              cartType={cartType}
              // preTipPrimaryCents={()}
              preTipTotalWithTax={preTipTotalWithTax}
            />}
            {(process.env.NODE_ENV !== 'production') && <PaymentRequestButton
              amount={Math.round(invoice * 100)}
              label="pay"
              handleTokenEvent={this.handleTokenEvent}
              ></PaymentRequestButton>}

            <h2 className="instruction">Enter card number:</h2>

            <StripePayment></StripePayment>
          </div>}



          <button
            onClick={this.handleCreateOrder}
            disabled={disableOrdering}
            className="button place-order-button">{
              (this.state.orderButtonPressed) ?
              "Placing Order..."  :
              "Place your Order"
            }</button>

            {outstandingOrderConditions[0] && <div className="out-standing-order-conditions">
              {outstandingOrderConditions.map(c =>
                <div>{c}</div>
              )}
            </div>}

            {this.state.orderSubmitErrorText && <div className="error-message">
              error: {this.state.orderSubmitErrorText.text}
            </div>}
        </div>


        <div className="price-summary">
          <h2 className="checkout__section-title">
            Order Summary
          </h2>
          <div role="text" className="subtotal line">
            <strong>Subtotal: </strong>
            <span className="subtotal__text">${round(totalPrice, 2)}</span>
            {(numberOfMarketPriceItems !== 0) ?
              <span> + {numberOfMarketPriceItems} market priced dish</span>
              : null}
          </div>
          {(cartType === 'DELIVERY') && <div role="text" className="delivery-fee line">
            <strong>Delivery: </strong>
            <span>${deliveryFeeInCents / 100}</span>
          </div>}
          {/* {(cart.state.appliedCashback > 0) && <div role="text" className="discounts line">
            <strong>Discount:</strong>
            <span className="discount-amount">- ${cart.state.appliedCashback / 100}</span>
          </div>} */}
          {(discount > 0) && <div role="text" className="discounts line">
            <strong>Discount:</strong>
            <span className="discount-amount">- ${discount}</span>
          </div>}
          <div role="text" className="tax line">
            <strong>Tax: </strong>
            <span>${tax}</span>
          </div>
          {settings.allServicePercentage &&
          <div role="text" className="service-fee line">
            <strong>Service Fee ({settings.allServicePercentage}%): </strong>
            <span>${allServiceFee}</span>
          </div>}
          {settings.allServicePercentage && settings.allServiceFeeNote && <div role="text" className="info">
            <span className="info">↳ {settings.allServiceFeeNote}</span>
          </div>}

          {showPreTip ? <div role="text" className="line">
            <strong>Tip: </strong>
            <span className="total__text">${preTipPrimaryCents / 100}</span>
          </div> : null}


          <div role="text" className="total line">
            <strong>Total: </strong>
            <span className="total__text">${invoice}</span>
          </div>
          {enableCashback && (Number(cashbackPercentage) > 0) && <div role="text" className="cashback">
            <strong>Cashback: </strong>
            <span>+ ${round((invoice) * cashbackPercentage / 100, 2)} ({cashbackPercentage}%)</span>
          </div>}
        </div>


      </div>
    )

  }
}

const CartItem = (item) => {
  let {price, photo, id, note, quantity} = item
  let name = item.name && item.name.en
  let description = item.description && item.description.en
  let pickedModgroups = calcItemPriceArray(item) && calcItemPriceArray(item).pickedModgroups
  return (
    <li className="item-card">

      {/* <a href="#" className="remove">remove</a> */}
      <div role="text" className="item-details">
        {photo && <div className="photo">
          <img
            alt=""
            src={makeCloudinaryUrlForPhoto(photo, {width: 100})}></img>
        </div>}
        <span className="checkout-quantity">
          {quantity}
        </span>
        <span aria-hidden>x</span>
        <span className="name-price">
          <h2><span className="name">{name}</span> <span className="price">${calcItemPrice(item)}</span></h2>
          <ModgroupDisplay pickedModgroups={pickedModgroups} />
          {note ? <p className="note">note: {note}</p> : null}
        </span>
      </div>
      <div className="actions">
        <Link className="button" to={`/cart/${id}`}>edit</Link>
        <button className="del button" onClick={item.removeFromCart.bind(this, id)}>remove</button>
      </div>
    </li>
  )
}

export default injectStripe(withRouter(injectShopContainer(injectCartContainer(CartCheckout))))
