

let psyburgerLayout = [
  {
    name: "Burgers🍔",
    width: 600
  },
  {
    name: "Psy-Style Toppings🌪",
    width: 200
  },
  {
    name: "Salads",
    width: 600
  },
  {
    name: "Beverages",
    width: 600

  }
]

module.exports = {
  'psy': {
    layout: psyburgerLayout,
    theme: 'dark',
    name: "Psy Burger",
    address: "15030 Ventura Blvd, Sherman Oaks,"
  },
  'pickmixjuice': {
    name: "Pick and Mix Juice",
    address: "539 N Glenoaks Blvd #104, Burbank"
  },
  'delimex': {
    name: 'Deli Mex',
    address: '14446 W Magnolia Blvd, Sherman Oaks',
  },
  'kairamen': {
    name: "Kai Ramen",
    address: '15030 Ventura Blvd #23, Sherman Oaks'
  },
  'donuthut': {
    name: 'Donut Hut',
    address: '2025 W Magnolia Blvd, Burbank'
  },
  'psh': {
    name: "Philadelphia Steak & Hoagie",
    address: "6427 Lankershim Blvd, North Hollywood"
  }
}
