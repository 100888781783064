import React, {useState} from 'react'
import {injectShopContainer} from '../react/ShopDataContainer'
import CartContainer, {injectCartContainer} from '../react/CartContainer'



function PromoCode ({cart, shop}) {
  function promoCodeChanged (e) {
    const code = String(e.target.value || '')
    if (code.toLowerCase() === '3yrs') {
      console.log('3YRS!')
      cart.setState({
        promoFreeDelivery: true
      })
    }
  }

  if (shop.shopId !== 'urban') { return null}
  return(
    <div className="promo-code">
      <label htmlFor="promo-code-input">Promo Code:</label>
      <input
        onChange={promoCodeChanged}
        id="promo-code-input" type="text"/>
      {cart.state.promoFreeDelivery && <div>
        <span
          style={{
            // fontWeight: 600,
          }}
          >promo code applied: free delivery</span>
      </div>}
    </div>
  )
}

const injectedPromoCode = injectShopContainer(injectCartContainer(PromoCode))

export default injectedPromoCode
