import React from 'react'
import {StripeProvider, Elements} from 'react-stripe-elements';

let STRIPE_PUBLIC_KEY
if (process.env.NODE_ENV === 'development') {
  STRIPE_PUBLIC_KEY = 'pk_test_4StUZOAUBEimWV33hJZZn5f5'
} else {
  STRIPE_PUBLIC_KEY = 'pk_live_Sq151n5SUu6qAtZDKtSdw0tv'
}


class StripeAsyncProvider extends React.Component {
  state = {
    stripe: null
  }
  componentDidMount() {
    if (window.Stripe) {
      this.setState({stripe: window.Stripe(STRIPE_PUBLIC_KEY)});
    } else {
      document.querySelector('#stripe-js').addEventListener('load', () => {
        this.setState({stripe: window.Stripe(STRIPE_PUBLIC_KEY)});
      });
    }
  }
  render() {
    // this.state.stripe will either be null or a Stripe instance
    // depending on whether Stripe.js has loaded.
    // console.log(this.state.stripe)
    return (


        <StripeProvider stripe={this.state.stripe}>

          <Elements>{this.props.children}</Elements>
        </StripeProvider>


    );
  }
}



export default StripeAsyncProvider
