import 'react-app-polyfill/ie11'
import 'core-js/fn/array/find';

import React, {createContext, useState} from 'react';
import { render } from 'react-dom';
import { BrowserRouter as Router, Link, Route, Switch, withRouter } from 'react-router-dom'
import App from './App';
import CustomComponentsProvider from './Custom/CustomComponentsProvider'
import './styles.css';
import {getRestaurantId} from "./utils"
import getCustomComponent from './Custom/getCustomComponent'
import ThemeContext from './Context/ThemeContext'


import * as Sentry from '@sentry/browser';
Sentry.init({ dsn: 'https://050b1e630bc64b56a3a91b1e6481bbb9@sentry.io/1042988' });
window.Sentry = Sentry

let restId = getRestaurantId()
const rootElement = document.getElementById('root');
// let renderFunc = rootElement.hasChildNodes() ? hydrate : render
let renderFunc = render // database often change which result in DOM mismatch, so never hydrate

const locations = getCustomComponent() && getCustomComponent().locations
const MultilocationHome = getCustomComponent() && getCustomComponent().MultilocationHome
const RootRouterContext = React.createContext(null)
export {RootRouterContext}
function LocationRouter ({history, basePath, shopId, cssGlobalPrefix}) {
  return (
    <RootRouterContext.Provider value={{rootHistory: history}}>
      <Router basename={basePath}>
      <CustomComponentsProvider locationId={shopId}>
        <App restId={shopId} cssGlobalPrefix={cssGlobalPrefix}></App>
      </CustomComponentsProvider>
      </Router>
    </RootRouterContext.Provider>
  )
}
const LocationWithRootRouter = withRouter(LocationRouter)

function Root () {
  var preferredTheme = '';
  try {
    preferredTheme = localStorage.getItem('theme');
  } catch (err) { }
  const [theme, setTheme] = useState(preferredTheme)
  window.__preferredTheme = theme
  function setSaveTheme (newTheme) {
    console.log('setting: ', newTheme)
    setTheme(newTheme)
    try {
      localStorage.setItem('theme', newTheme);
    } catch (err) {}
  }
  window.__setPreferredTheme = setSaveTheme
  return(
    <ThemeContext.Provider value={{
      themeName: theme,
      setTheme: setSaveTheme
    }}>
      <Router>
        <Switch>
          {locations && locations.map(({shopId, basePath, cssGlobalPrefix}) => {
            console.log('branch location: ', shopId)
            return(
                <Route key={basePath} path={basePath} render={() => (
                    <LocationWithRootRouter basePath={basePath} shopId={shopId} cssGlobalPrefix={cssGlobalPrefix} />
                  )} />

            )
          })}

          {MultilocationHome && <Route component={MultilocationHome} />}

          <Route>
            <CustomComponentsProvider>
              <App restId={restId}></App>
            </CustomComponentsProvider>
          </Route>
        </Switch>

      </Router>
    </ThemeContext.Provider>
  )
}


renderFunc(
  <Root></Root>
  ,
  rootElement
)
