import React, {useState, useEffect} from 'react'
import {injectStripe, PaymentRequestButtonElement} from 'react-stripe-elements';


function PaymentRequestButton ({ label, amount, stripe, handleTokenEvent }) {
  const [canMakePayment, setCanMakePayment] = useState(false)
  const [paymentRequest, setPaymentRequest] = useState(false)

  useEffect(function getToken () {
    if (!stripe) {return}
    setCanMakePayment(false)
    const paymentRequest = stripe.paymentRequest({
      country: 'US',
      currency: 'usd',
      total: {
        label,
        amount,
      },
    });
    setPaymentRequest(paymentRequest)

    paymentRequest.on('token', (response) => {
      const {complete, token, ...data} = response
      console.log('Received Stripe token: ', token);
      console.log('Received customer information: ', data);
      // complete('success');
      handleTokenEvent(response)
    });

    paymentRequest.canMakePayment().then((result) => {
      console.log('can make?', result)
      setCanMakePayment(!!result);
    }).catch((e) => {
      console.log(e)
    })
  }, [label, amount, stripe])

  return(
    <div className="paymentRequestButton">
      {canMakePayment ?
      <PaymentRequestButtonElement
        paymentRequest={paymentRequest}
        className="PaymentRequestButton"
        style={{
          // For more details on how to style the Payment Request Button, see:
          // https://stripe.com/docs/elements/payment-request-button#styling-the-element
          paymentRequestButton: {
            theme: 'light',
            height: '64px',
          },
        }}
      />
       : null}
    </div>
  )
}

export default injectStripe(PaymentRequestButton)
