
import * as Components from "./Phoburbank"

export default function getCustomComponent () {
  return Components
}

export function getShopId () {
  return "phoburbank"
}
