import React, {useState} from 'react';
import {Link, withRouter} from 'react-router-dom'

import './DriveIn.scss'



 function DriveIn ({orderLink, history, setHideNotice}) {
  const orderingLink = orderLink || '/order'

  const types = ['DriveIn CarHop', 'Cubside', 'Delivery']

  function OrderLink({ tracking='' }) {
    function onClick (e) {
      e.preventDefault()
      setHideNotice(true)
      history.push(orderingLink + tracking)
    }
    return(
      <a href="/order" onClick={onClick}>Order Online</a>
    )
  }

  return(
    <div className="drive-in-wrapper">
      <div className="drive-in">
        <h1><span className="tag contact-free">Contact Free Options</span></h1>
        <div className="options">
          {/* <div className="item"><div className="header">

              <h2>CarHop</h2>

              <p>Park and let us serve you at your car <span className="tag contact-free">contact free</span>
              with
              <span className="highlight">FREE DRINK REFILLS</span>
            </p>

            </div>
            <div className="content">
              <ol>
                <li>Drive up and Park</li>
                <li>Call / <OrderLink tracking="/?from=drivein"></OrderLink> <span className="tag contact-free">(Contact Free)</span>
                <div className="hint">write carhop & car color/model next to name</div>
              </li>


              </ol>

              <h2></h2>
            </div>
            </div> */}

        <div className="item">
            <div className="header">

            <h2>Curbside ToGo</h2>


          </div>
          <div className="content">
            <ol>
              <li>Call / <OrderLink></OrderLink> <span className="tag contact-free">(Contact Free)</span>
              <div className="hint">put car color/model next to name</div>
            </li>
              <li>When you get here, call us</li>

            </ol>
        </div>


          </div>
          <div className="item">
            <div className="header">

              <h2>Delivery</h2>



            </div>
            <div className="content">
              <ol>
                <li><OrderLink></OrderLink></li>
                <li>Put in Delivery Intructions to leave at door and call. <span className="tag contact-free">(Contact Free)</span></li>
              </ol>


            </div>
          </div>
        </div>
        </div>

    </div>
  )
}

export default withRouter(DriveIn)
