import {round} from 'lodash'

export default function calcDelivery ({
  miles,
  minutes,
  deliverySettings,
  subtotal
}) {
  console.log('subtotal:', subtotal)
  let {
    baseFeeInCents=0,
    maxDistanceInMiles=5,
    feeInCentsPerMile=0,
    baseFeeMiles=0,
    freeDeliveryQualifyingSubtotalInCents,
    useStepFunction=false
  } = deliverySettings
  miles = Number(miles); minutes = Number(minutes); baseFeeInCents = Number(baseFeeInCents); maxDistanceInMiles = Number(maxDistanceInMiles);
  feeInCentsPerMile = Number(feeInCentsPerMile); baseFeeMiles = Number(baseFeeMiles); freeDeliveryQualifyingSubtotalInCents = Number(freeDeliveryQualifyingSubtotalInCents)
  // console.log('miles'; miles, baseFeeInCents)
  let additionalMiles = 0
  if ((miles - baseFeeMiles) > 0) {
    additionalMiles = (miles - baseFeeMiles)
  }

  // calculate standard base fees
  let deliveryFeeInCents =
    round(
      baseFeeInCents +
      feeInCentsPerMile * additionalMiles
    )

  //TODO: twoguys step functon temporary hotfix
  if (useStepFunction) {
    deliveryFeeInCents = (miles > 5) ? 500 : 195
  }

  // check if qualify for free
  if ((freeDeliveryQualifyingSubtotalInCents) && (Number(subtotal) * 100 > freeDeliveryQualifyingSubtotalInCents)) {
    deliveryFeeInCents = 0
    console.log('freeeee', freeDeliveryQualifyingSubtotalInCents)
  }
  // console.log('fee:', deliveryFeeInCents)
  let isDeliverable = (miles < maxDistanceInMiles)
  if (!isDeliverable) {
    deliveryFeeInCents = 0
  }
  return {
    isDeliverable,
    deliveryFeeInCents
  }
}
