import React from 'react'
import {StripeProvider, Elements, CardElement, AddressSection} from 'react-stripe-elements';
// import InjectedCCForm from './CCForm'
// import InjectedPaymentRequestForm from './PaymentRequestForm'

class StripePayment extends React.Component {


  render() {

    return (

        <div className="payment-container">

          <div>
            {/* <AddressSection></AddressSection> */}
            <CardElement
              onChange={(changeObject) => { console.log(changeObject) }}
              style={{base: {fontSize: '18px'}}} />

            {/* secure checkout by <a href="https://stripe.com">Stripe</a> */}
          </div>





        </div>

    );
  }
}

export default StripePayment
